

export const listLaboralExperience = ( state ) => {
    return state.candidate?.laboralExperience || ''
}

export const listEducation = ( state ) => {
    return state.candidate?.educationExperience || ''
}
export const listLanguageExperience = ( state ) => {
    return state.candidate?.languageExperience || ''
}
export const getUser=(state)=>{
    return state.candidate?.candidate || ''
}
export const assignOfferCandidate=(state)=>{
    return state.candidate?.candidate.jobsId || ''
}
export const getLanguage=(state)=>{
    return state.language ||''
}