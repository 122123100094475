import {ref,computed} from 'vue'
import setescaApi from "@/modules/api/setescaApi"
import { useRouter } from 'vue-router'
import setescaSecurity from "@/modules/api/setescaSecurity"
import useCandidates from '@/modules/candidates/composables/useCandidate'
import {useStore} from 'vuex'
const useSecurity = () =>{

    const store =useStore()
    const router = useRouter()
    const logued=ref(false)
    const msgUser=ref('')
    const { getUsers } =useCandidates()
    const validUserName=ref(false)
    const user=ref({
        'Id':0,
        'UserName':'',
        'Password':'',
        'CreationDate':'',
        'LasAccessDate':'',
        'DeleteDate':'',
        'Verified':'',
        'VerifiedCode':'',
        'VerifiedDate':'',
        'Discontinued':'',
        'ProfesionalId':'',
        'UserTypeId':'',
        'FirstName':'',
        'LastName':'',
        'Token':'',
        'BizneoId':'',
        'LinkedinId':'',
        'InfojobsId':''
    })
    const auth=ref({
        'userName':'',
        'password':''
    })
    
    function authentication(){
                 
        var c=auth.value
        console.log(c);
        setescaSecurity.post("users/authenticate",{userName: c.userName, password: c.password}).then(response=>{            
            console.log(response.data)
            store.dispatch('security/addUser',response.data)    
            console.log(response.status)
            if(response.status==200)
            {
                if (response.data.id>0)
                {  
                    router.push({name: 'Home'})
                    logued.value=false
                }else
                {
                    logued.value=true
                    msgUser.value="Los datos introducidos no son correctos"
                    store.dispatch("security/addUser",null)
                }
                getUsers(response.data.profesionalId)
                console.log("Despues de ir a buscar el usuario"+response.data.profesionalId)
            }else
            {
                logued.value=true
                msgUser.value="Los datos introducidos no son correctos"
            }

                 
        }).catch((error)=>{
            logued.value=true
            msgUser.value="Los datos introducidos no son correctos"
        })
                      

    }

    const validateUser=async(username)=>
    {
        setescaSecurity.get("/users/verify/"+username).then(response=>{    
            
            if(response.data)
            {
                msgUser.value="El usuario ya existe."
                validUserName.value=true
                console.log("El usuario ya existe")
                console.log(msgPass.value)
            }else
            {
                msgUser.value=""
                validUserName.value=false
                console.log("El usuario NO existe")
            }         
         
        })           
    }

    const closeSession=async()=>{

        store.dispatch('security/addUser',null)   
        router.push({name: 'Home'})

    }
    const getUser = async() => {
     
        console.log("VERIFICANDO SI EL USUARIO SE HA LOGADO")        
        logued.value=false
        console.log(logued.value)
       
   
    }    
    return{
        user
        ,auth
        ,authentication
        ,closeSession
        ,msgUser
        ,logued
        ,validateUser
        ,getUser
        ,validUserName
        , exisUser: computed(()=> store.getters["security/getUser"])

    }
}
export default useSecurity