export const addOffers=(state,offers)=>
{
  state.offers.InfojobsOffers=new Array()
  console.log("el array "+offers.length)
  state.offers.InfojobsOffers.push(offers)
  
}
export const setescaAddOffers=(state,offers)=>
{
  state.offers.SetescaOffers=new Array()
  console.log("el array "+offers.length)
  state.offers.SetescaOffers.push(offers)
  
}

export const addOffer=(state,offerId)=>
{
  console.log("MUTATTION :Agregando la oferta al candidato.")  
    state.offerId=offerId
}

export const addConditions=(state,query)=>
{
  state.search=query
}