export const addOffers =async ({commit},offers)=>
{
   console.log(offers)
   console.log("ACTIONS :  a la mutation para agregar las ofertas laborales")
   commit('addOffers',offers)
}

export const setescaAddOffers =async ({commit},offers)=>
{
   console.log(offers)
   console.log("ACTIONS :  a la mutation para agregar las ofertas laborales")
   commit('setescaAddOffers',offers)
}

export const addOffer=async ({commit},offerId)=> 
{
   console.log(offerId)
   console.log("ACTIONS :  a la mutation para marcar la oferta ")
   commit('addOffer',offerId)
   
}

export const addConditions=async ({commit},query)=>
{
   commit('addConditions',query)
}