<template>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="row">
      <div class="row fondoImagen">
            <div class="col-lg-4 col-md-3"></div>
            <div class="col-lg-4 col-md-6" >
            <a href="https://www.setescatalent.com/find-talent" target="_blank">
                <el-card class="box-card" shadow="never">
                  <div class="row">
                    <div class="col-12">
                      <h1>SETESCA TALENT INSIGHTS</h1>                              
                    </div>                           
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-12">
                      <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>     
                      <h3>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor</h3>
                      <h3>Lorem ipsum dolor sit amet,  sed do eiusmod tempor</h3>                         
                    </div>
                  </div>   
                  <br/>
                  <div class="row">
                    <div class="col-12">
                      <el-button type="primary" plain  style="margin-bottom:5px;margin-right:5px;">Learn More</el-button>                                          
                    </div>
                  </div>                                                 
                </el-card>                
            </a>

            </div>
            <div class="col-lg-4 col-md-3"></div>                
      </div>     
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.fondoImagen{
    background:  url("../../../assets/images/SetescaTalentInsight.png") no-repeat center top;
    min-height:500px; 
    border-radius: 20px 20px 20px 20px;
    padding-top:65px;
    padding-bottom:25px;
    width:100%;
    background-size: cover;
  }
  
</style>