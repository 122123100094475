<template>
  <div class="container-fluid">
    <div class="row d-none d-lg-block .d-xl-none">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a href="https://www.setescatalent.com/chatgpt" target="_blank">
            <div class="col-12" style="background-color:#0476e0;height:320px;text-align:center;">
              <div class="row" style="padding-top:3%;">
                  <div class="col-lg-5 col-md-12" style="text-align:right;">
                    <img src="@/assets/images/logoChatGPT.png" class="img-fluid img-responsive" width="160px" height="160px" />
                  </div>
                  <div class="col-lg-7  col-md-12" style="font-size: 100px;color:white;text-align:center;padding-top:65px;text-align:left;">
                    <span >ChatGPT</span>
                  </div>       
              </div>    
              </div>
          </a>   
        </div>      
    </div>
    <div class="row d-none d-md-block d-lg-none">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <a href="https://www.setescatalent.com/chatgpt" target="_blank">
          <div class="col-12" style="background-color:#0476e0;height:320px;">
            <div class="row">
                <div class="col-md-12" >
                  <img src="@/assets/images/logoChatGPT.png" class="img-fluid img-responsive" width="160px" height="160px" />
                </div>
                <div class="col-md-12" style="font-size: 100px;color:white;text-align:center;padding-top:65px;text-align:center;">
                  <span >ChatGPT</span>
                </div>       
            </div>    
            </div>
        </a>   
      </div>      
    </div>
  </div>


</template>

<script>
export default {

}
</script>

<style>

</style>
