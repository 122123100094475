<template>
<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid"><img src="@/assets/images/logo-Setesca-Talent.webp" class="img-fluid"/> 
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">                         
                <li class="nav-link"> <a class="nav-link" href="#">Home</a> </li>
                <li class="nav-link"> <a class="nav-link" href="#">Busco Talento</a> </li>
                <li class="nav-link"> <a class="nav-link" href="#">Busco Trabajo</a> </li>
                <li class="nav-link"> <a class="nav-link" @click="goToContact">Contactanos</a> </li>                            
            </ul>
            <div>  
                <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a href="#" v-if="exisUser!=null" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" aria-haspopup="true" id="item-home">{{exisUser.firstName}} {{exisUser.lastName}}</a>
                            
                            <div class="dropdown-menu" aria-labelledby="item-home">
                                <a @click="gotoMiCv" class="dropdown-item">
                                    <span>Mi Curriculum Vitae</span>
                                    <div class="dropdown-divider d-none d-lg-block"></div>
                                </a>
                                <a @click="gotoOffers" class="dropdown-item">
                                    <span>Mis Ofertas</span>
                                    <div class="dropdown-divider d-none d-lg-block"></div>
                                </a>     
                                <a @click="closeSession" class="dropdown-item">
                                    <span>Cerrar mi sesión</span>
                                    <div class="dropdown-divider d-none d-lg-block"></div>
                                </a>                                                              
                            </div>
                        </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
</template>

<script>
import useSecurity from '@/modules/security/composables/useSecurity'
import { useRouter } from 'vue-router'
export default {   
  setup() {
    const router = useRouter()
    const {exisUser,logued,getUser,closeSession} = useSecurity()
    const gotoMiCv= () =>{router.push({name: 'mycv'})}
      const gotoOffers= () =>{router.push({name: 'myoffers'})}    
     // const gottoCloseSession= () =>{closeSession}    
    getUser()
    return{
      exisUser,logued,gotoMiCv,gotoOffers,closeSession
    }
    
  }
    
   
}
</script>

<style>

</style>