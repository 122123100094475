

export const addLaboralExperience =async ({commit},laboralExperience)=>
 {
    console.log("ACTIONS : llamando a la mutation para agregar la experiencia laboral")
    commit('addLaboralExperience',laboralExperience)
 }
 
 export const addLanguageExperience =async ({commit},languageExperience)=>
 {
    console.log("ACTIONS : llamando a la mutation para agregar la los idiomas")
    commit('addLanguageExperience',languageExperience)
 }


 export const addEducationExperience =async ({commit},EducationExperience)=>
 {
    console.log("ACTIONS :  a la mutation para agregar la Educacion")
    commit('addEducationExperience',EducationExperience)
 }

 export const assignOfferCandidate=async({commit},offerId)=>
 {
   console.log("ACTIONS ")
   commit('assignOfferCandidate',offerId)
 }

export const createUser=async ({commit},candidate)=>
{
  try{
      console.log(candidate);
      commit('createUser',candidate)
      return 1
  }catch(error)
  {
    console.log(error)
    return 0
  }

}

export const addLanguage=async({commit},language)=>
{
  try{

       console.log("actions")
       console.log(language)
      commit('addLanguage',language)

    }catch(error)
    {
      console.log(error)
    }
}
