
export const createUser=(state,candidate)=>
{
  console.log("agregnado usuario en la accion ")
  console.log(candidate)
  state.candidate=candidate
}


export const addLaboralExperience=(state,laboralExperience)=>
{
  console.log("MUTATTION :Agregando la experiencia laboral.")
 // state.candidate.laboralExperience.push(Object.assign({}, laboralExperience))
  state.candidate.laboralExperience.unshift(Object.assign({}, laboralExperience))
  
}


export const addLanguageExperience=(state,languageExperience)=>
{
  console.log("MUTATTION :Agregando los idiomas.")
  console.log(languageExperience)
  state.candidate.languageExperience.push(Object.assign({}, languageExperience))
  
}

export const addEducationExperience=(state,EducationExperience)=>
{
  console.log("MUTATTION :Agregando la educacion.")  
  console.log(EducationExperience)
    state.candidate.EducationExperience.push(Object.assign({},EducationExperience))
}

export const assignOfferCandidate=(state,offerId)=>
{
  console.log("MUTATTION :Agregando la oferta al candidato.")  
    state.candidate.jobsId=offerId
}

export const addLanguage=(state,language)=>
{
    console.log(language)
    state.language=language
}

export const addLaboral=(state,laboral)=>
{
  console.log(laboral)
  state.laboral=laboral
}

export const addEducation=(state,education)=>
{
  console.log(education)
  state.education=education
}