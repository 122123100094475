import {ref} from 'vue'
import { computed } from 'vue'
import {useStore} from 'vuex'
import setescaApi from "@/modules/api/setescaApi"
const useBestPractices = () =>{

     const answwer=ref([])
     const questions=ref([])
     const visible=ref(0)

    function Suggestions(title)
    {
        console.log('title '+title)
      
        setescaApi.get("/bestpractices?title="+title).then(response=>{    
            questions.value=response.data
            console.log(questions.value)
        })         
    }

    function SearchSuggestions()
    {
        setescaApi.get("/bestpractices/all").then(response=>{    
            questions.value=response.data
        })  
    }

    function Search(title)
    {
        console.log(title)
        setescaApi.get("/bestpractices/search?title="+title).then(response=>{    
            answwer.value=response.data
            if(answwer.value.length==0)
            {
                visible.value=1
            }else
            {
                visible.value=2
            }
        })         
    }   
    

    return{
        Search,questions,Suggestions,answwer,SearchSuggestions,visible
    }
}

export default useBestPractices