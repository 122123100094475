import {ref} from 'vue'
import { computed } from 'vue'
import {useStore} from 'vuex'
import setescaApi from "@/modules/api/setescaApi"
const useCandidate = () =>{

     const store =useStore()
     const user=ref(store.state['candidate'])
     const messageOK=ref('')
     const messageKO=ref('')
     const validOK =ref(false)
     const validKO=ref(false)
    const candidate=ref({
        id:'',
        firstName:'',
        lastName:'',
        email:'',
        userName:'',
        password:'',
        phone:'',
        birthDate:{day:'',month:'',year:''},
        gender:'',
        postalCode:'',
        locality:'',
        province:'',
        laboralExperience:[],
        education:[],
        validated:false,
        account:{username:'',password:''},
        laboralExperience:[],
        languageExperience:[],
        EducationExperience:[],
        linkFacebook:'',
        linkLinkedin:'',
        linkGoogle:'',
        linkInstagram:'',
        linkTwitter:'',
        linkSkype:'',
        linkYoutube:'',
        linkPersonal:'',
        jobsId:0
    })

    const laboralExperience=ref({
        position:'',
        company:'',
        startDate:{day:'',month:'',year:''},
        endDate:{day:'',month:'',year:''},
        actualy:'',
        description:''
    })      
    
    
    const educationExperience=ref({
        id:0,
        level:'',
        estudie:'',
        center:'',
        startDate:{day:'',month:'',year:''},
        endDate:{day:'',month:'',year:''},
        actualy:'',
        description:''
    })              

    const language=ref({
        id:0
        ,language:''
        ,level:''
        ,level_Read:''
        ,level_Written:''
        ,level_Spoken:''
    })

    /***************************** METODOS  ********************************************************/

    const paso1=async(candidate)=>{               
            console.log("Entrando al composable" + candidate.value.password)      
            candidate.value.userName=candidate.value.userName
            candidate.value.account.username=candidate.value.userName
            candidate.value.account.password=candidate.value.password
            const resp = await store.dispatch('candidate/createUser', candidate)
            console.log("el valor de resp")  
            console.log(resp)
            return resp
    }

    const register=async()=>{
        
        if(candidate.id==0)
          {
            const resp = await store.dispatch('candidate/createUser', candidate) 
            console.log("el valor de resp")  
            console.log(resp)
          }else      

      
        candidate.value=store.state.candidate.candidate
        var c=candidate.value
        console.log(c);
        setescaApi.post("bizneo/candidates/register",{c}).then(response=>{            
            console.log(response.data)
        })      
        return resp
    }
   
    const getUsers=async(id)=>{
        setescaApi.get("/candidates?id="+id).then(response=>{    
           
            console.log(response.data)
 
            const resp =  store.dispatch('candidate/createUser', response.data)   
            for (var i=0;i<response.data.EducationExperience.length;i++)
            {
                 educationExperience.value=response.data.EducationExperience[i]
                 console.log("EDUCATION ")
                 console.log(educationExperience.value)
                 addEducation()
            }

            for (var i=0;i<response.data.ProfessionalExperience.length;i++)
            {
                laboralExperience.value=response.data.lProfessionalExperience[i]
                 console.log("PROFESIONAL ")
                 console.log(laboralExperience.value)
               //  addLaboralExperience(laboralExperience.value)
            }

           // response.data.lEducationExperience.forEach(addEducation(educationExperience))
           // response.data.lProfessionalExperience.forEach(addLaboralExperience(laboralExperience))
           // response.data.lLanguageExperience.forEach(addLanguage(languageExperience))

              
           
        })          


    }

    const addLaboralExperience=async(laboralExperience)=>{                  
        try{
                console.log("AGREGAR EXPERIENCIA PROFESIONAL")
                console.log(laboralExperience.value)
                store.dispatch('candidate/addLaboralExperience',laboralExperience)                
                return { ok : true,id:laboralExperience.id+1}
        }catch(error)
        {
            console.log("ERROR : AddLaboralExperience "+error)
            return { ok : false,id:0}
        }              
    }

    const addEducation=async()=>{       
           
        try{
            console.log("AGREGANDO EXPERIENCIA EN FORMACION")
            console.log(educationExperience.value)
            store.dispatch('candidate/addEducationExperience',educationExperience.value)
            return {ok:true,id:educationExperience.id+1}
            
        }catch(error)
        {
            console.log("ERROR : AddEducationExperience "+error)
            return { ok : false,id:0}
        }      
    }

    const addLanguage=async()=>{

        try{
            messageOK.value=""
            messageKO.value=""
            validKO.value=false
            validOK.value=false            
             console.log("nivel de escritura")
            console.log(language.value.level_written)
            if (language.value.language!='0')
            {
                if(language.value.level=='0')
                {
                    messageOK.value=""
                    messageKO.value="Debe seleccionar el nivel medio"
                    validKO.value=true
                    validOK.value=false
                    return
                }
                if(language.value.level_Read=='0')
                {
                    messageOK.value=""
                    messageKO.value="Debe seleccionar nivel de lectura"
                    validKO.value=true
                    validOK.value=false 
                    return
                }
                if (language.value.level_Written=='0')
                {
                    messageOK.value=""
                    messageKO.value="Debe seleccionar nivel de escritura"
                    validKO.value=true
                    validOK.value=false
                    return
                }
                if (language.value.level_Spoken=='0')
                {
                    messageOK.value=""
                    messageKO.value="Debe seleccionar nivel de hablado "
                    validKO.value=true
                    validOK.value=false
                    return
                }
                   
            }else
            {
              messageKO.value=""
              messageOK.value="Debe seleccionar un idioma"
              validKO.value=true
              validOK.value=false
            }
            
            console.log("OK : "+validKO.value)
            
            if(!validKO.value)
            {
                messageKO.value=""
                messageOK.value="Se ha agregado correctamente el idioma"
                validKO.value=false
                validOK.value=true
                store.dispatch('candidate/addLanguageExperience',language.value)
                return {ok:true,id:language.id+1}                           
            }

            
        }catch(error)
        {
            console.log("ERROR : addLanguageExperience "+error)
            messageOK.value="ERROR :"+error
            validKO.value=false
            validKO.value=true
            return { ok : false,id:0}
        } 

    }

    const editLanguage=async(index)=>{
       
            console.log(index)
            var languages=store.getters["candidate/listLanguageExperience"]            
            language.value.language=languages[index].id
            language.value.level=languages[index].level
            language.value.level_Read=languages[index].level_Read
            language.value.level_Writen=languages[index].level_Writen
            language.value.level_Read=languages[index].level_Read
            language.value.level_Spoken=languages[index].level_Spoken
            language.value.level_Written=languages[index].level_Written 
            console.log(language)
            console.log("despues del objeto")
            store.dispatch('candidate/addLanguage',language.value)
            
   
    }

    const updateLanguage=async(index)=>
    {
            consoel.log(index)
    }

    const clearLaboralExperience=async()=>
    {
            try{

               laboralExperience.value.position='' 
               laboralExperience.value.company=''
               laboralExperience.value.actualy=''
               laboralExperience.value.description=''
               console.log(laboralExperience.value.startDate.day)
               laboralExperience.value.startDate={day:'',month:'',year:''}
               laboralExperience.value.endDate={day:'',month:'',year:''}        
               laboralExperience.value.actualy=""
               laboralExperience.value.description=""                                                            
            }catch(error)
            {
                console.log("ERROR :"+error)
            }
    }

    const clearEducation=async()=>
    {
        educationExperience.value.id=0,
        educationExperience.value.level='',
        educationExperience.value.estudie='',
        educationExperience.value.center='',
        educationExperience.value.startDate={day:'',month:'',year:''},
        educationExperience.value.endDate={day:'',month:'',year:''},
        educationExperience.value.actualy='',
        educationExperience.value.description=''
    }

    const clearLanguage=async()=>
    {
        console.log(language)
        language.id=0,
        language.value.language='0'
        language.value.level='0'
        language.value.level_Read='0'
        language.value.level_Written='0'
        language.value.level_Spoken='0'

        messageOK.value=""
        messageKO.value=""
        validKO.value=false
        validOK.value=false        
        console.log(language)
    }

    return{
        register,
        paso1,
        getUsers,
        candidate,
        educationExperience,
        laboralExperience,
        language,
        addLaboralExperience,
        addEducation,
        clearEducation,
        clearLaboralExperience,
        clearLanguage,
        editLanguage,
        addLanguage,
        updateLanguage,
        messageKO,
        messageOK,
        validKO,
        validOK,   
        listLaboralExperience: computed(()=> store.getters['candidate/listLaboralExperience']),
        listEducation: computed(()=> store.getters['candidate/listEducation'])      

        
    }

}
export default useCandidate