<template>
  <div class="container-fluid">
    <div>
       <br/>
    </div>
    <div class="container-fluid" >
      <router-view/>
    </div>
    <br/>
     
    <div class="container-fluid">
       <Footer/>     
    </div>

  </div>
  </template>
  <script>
  import NavBar from "@/modules/general/components/NavBar.vue"
  import NavBarLog from "@/modules/general/components/NavBarLogin.vue"
  import Footer from "@/modules/general/pages/Footer.vue"
  import {watch} from 'vue'
  import {useStore} from 'vuex'
  import useSecurity from '@/modules/security/composables/useSecurity'
  export default {
     
     components: {  
       NavBar,Footer,NavBarLog
    },
    setup() {
      const store =useStore()    
      const {exisUser,logued,getUser} = useSecurity()
      getUser()
      return{
        exisUser,logued
      }
      
    }
      
     
  }
  </script>
  
  <style>
  #app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  #nav {
    padding: 30px;
  }
  
  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  
  #nav a.router-link-exact-active {
    color: #42b983;
  }
  </style>
  