import { createStore } from 'vuex'
import candidate from '@/modules/candidates/store'
import offers from '@/modules/Offers/store'
import security from '@/modules/security/store'
 const store=createStore({
   modules:{
     candidate,offers,security
   }
 })



export default store