<template>
 <div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <el-card style="min-height:100px;margin-bottom:15px;">    
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
                    <img src="@/assets/images/6.jpg" class="image"/>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12">
                  <div style="padding: 14px;">
                    <p><H2 style="padding-top:1%;margin-top:10%;text-align:left;">BEST TRAINING TO BOOST YOUR CAREER</H2></p>      
                    <p style="text-align:justify;">The offer the best online training courses for you to boost your career..</p>         
                  </div>              
                  <div class="col-11 col-sm-12">
                    <a href="https://www.liceotic-training.com/english-training" target="_blank">
                      <div style="width:100%;position:relative;top:55%;z-index: 999;background-color:#0476e0;color:white;font-size:24px;padding-top:5%;padding-bottom:5%;">
                      Best Training For You
                      </div>               
                    </a>                    
                  </div>
                  <div class="col-1"></div>                   
              </div>                             
            </div>
            <br/>
          </el-card>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <el-card style="min-height:100px;margin-bottom:15px;">    
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
                    <img src="@/assets/images/1.jpg" class="image"/>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12">
                  <div style="padding: 14px;">
                    <p><H2 style="padding-top:1%;margin-top:10%;text-align:left;">SETESCA TALENT INSIGHTS</H2></p>      
                    <p style="text-align:justify;">Recommendations and resources created by the leaders in IT talent selection are available now. Find the best practices, salary reports and much more to you advance your career.</p>         
                  </div>                
                  <div class="col-11 col-sm-12" >
                    <a href="https://www.setescatalent.com/talent-insigths" target="_blank">
                      <div style="width:100%;position:relative;top:55%;z-index: 999;background-color:#0476e0;color:white;font-size:24px;padding-top:5%;padding-bottom:5%;">
                        Visit Talent Insights
                      </div>               
                    </a>                    
                  </div>
                  <div class="col-1"></div>                   
              </div>                             
            </div>
            <br/>
          </el-card>
        </div>     
    </div> 
 </div>
</template>

<script>
export default {

}
</script>

<style scoped> 
.buttomTalent{
    background-color:#0476e0;
    display:inline-block;
    position:relative;
    color:white;
    width:100%;
    
    font-size:18px;
    z-index:99;
    padding-top:20px;
    padding-bottom:20px;
    text-align:center;
    border-radius:15px 15px 15px 15px;
    width:100%;
    
  }
  .buttomTalent:hover, .buttomTalent:focus {
    color:#0476e0;
    background-color: #c4d4ec;
  }
</style>