<template>
  <div class="container-fluid">
    <div class="col-12">
      <div id="hero-area" class="container-fluid parallaxjobs-search overlay" data-stellar-background-ratio="0.5">
        <div class="hero-main" id="heading">
          <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4 col-md-12 col-sm-12 d-none .d-sm-block" style="padding-top:120px;"><span style="font-size: 58px;color:white;text-align:left;">knowledge center</span></div>
                <div class="col-xl-4 col-md-12 col-sm-12 d-none d-block .d-sm-none" style="padding-top:120px;"><span class="display-5" style="color:white;text-align:left;">knowledge center</span></div>
                <div class="col-xl-4 col-md-6 col-sm-6" style="padding-top:5px;">  
                  <img src="@/assets/images/LOGO-blau@2x.svg" class="img-fluid img-responsive" alt="isthisjob4me"/>
                </div>
               
                <div class="col-xl-4 col-md-6 col-sm-6" style="padding-top:80px;"> 
                  <img src="@/assets/images/setescatalent.svg" class="img-fluid img-responsive" style="width:50%" height="50%"/>
                </div>              
            </div>
            </div>                      
          </div>
        </div>   
    </div> 
    <br/>
    <div class="row">
      <div class="col-12" >
        <Section3/>
      </div>
    </div>
    <div class="row">
      <div class="col-12" >
          <div id="hero-area" class="parallaxjobs-search overlay" data-stellar-background-ratio="0.5" style="border-radius:0px 0px 25px 25px;">
            <div class="hero-main" id="heading" style="padding-top:55px;padding-bottom:55px;">
              <div class="container">
                    <div class="row">
                        <div class="col-12">                                                                                           
                           <h1  style="color:white;text-transform:none">Please input your question</h1>
                                <div class="trip-search" >     
                                    <div class="row">
                                        <!-- Form Lookin for -->
                                        <div class="col-xl-10">
                                          <div class="form-group looking" style="width:100%;">
                                              <div class="first-select wide" style="height:75px;padding-top:15px;">
                                                  <div class="main-search-input-item" style="padding-right:5px;">                                                         
                                                      <el-autocomplete
                                                      v-model="title"
                                                      :fetch-suggestions="querySearch"
                                                      :trigger-on-focus="false"
                                                      clearable
                                                      class="inline-input w-100"
                                                      placeholder="Please Input your answer"
                                                      @select="handleSelect"/>
                                                  </div>
                                              </div>
                                          </div>                                                
                                        </div>
                                        <!--/ End Form Lookin for -->
                                        <!-- Form Button -->
                                        <div class="col-xl-2 ">
                                          <div class="form-group button" style="height:45px;margin-top:15px;">
                                              <button type="button" style="background-color:#0476e0;" class="btn" @click="Search(title)">Search</button>
                                          </div>                                                
                                        </div>
                                        <br/>
                                        <!--/ End Form Button -->
                                    </div>                                
                                </div>                           
                              <br/>
                        </div>
                    </div>
              </div>
            </div>   
          </div>
      </div>
    </div>  
    <br/>
    <div class="row">
      <div class="col-12">
            <el-card shadow="never" style="margin-bottom:15px;border-radius: 20px 20px 20px 20px;" v-show="visible==1">
              <div class="row">
                <div class="col-12">
                  <el-alert title="No se han encontrado resultados para la busqueda realizada" type="warning" show-icon />
                </div>
              </div>
            </el-card>  
            <div class="container" style="margin-top:25px;min-heigth:400px;margin-bottom:55px;" v-show="answwer.length>0">
              <el-card shadow="never"  v-for="ans in answwer" :key="ans.id" style="margin-bottom:15px;border-radius: 20px 20px 20px 20px;">
                  <div class="row" >  
                      <div class="row">
                          <div class="col-xl-12"><h2 style="padding-top:15px;">{{ ans.title }}</h2></div>
                        
                      </div>          
                      <div class="row"><div class="col-12"><hr/></div></div>     
                      <div class="row">
                          <div class="col-12"><div v-html="ans.description" style="text-align:lefT;"/> </div>          
                      </div>  
                      <div class="row"><div class="col-12"><hr/></div></div>
                      <div class="row">
                          <div class="col-lg-8 col-md-12"></div>
                          <div class="col-lg-4 col-md-12">
                            <a :href="ans.link" class="nav-link text-dark font-italic bg-light" target="_blank">
                              <i class="fa fa-th-large mr-3 text-primary fa-fw"></i>
                              More Info
                            </a>                          
                          </div>                       
                      </div>    
                  </div>
              </el-card>
            </div>
      </div>    
    </div>
    <br/>
    <div class="row">
      <div class="col-12">
        <Section2/> 
      </div>      
    </div>
    <br/>
    <div class="row">
       <div class="col-12" >
        <Section4/>   
      </div>     
    </div>      
</div>
  
 
</template>
  
  <script>
   import { ref, reactive } from 'vue'
  import useBestPractices from '@/modules/BestPractices/composables/useBestPractices.js'
  import Section1 from '@/modules/BestPractices/components/Section1.vue'
  import Section2 from '@/modules/BestPractices/components/Section2.vue'
  import Section3 from '@/modules/BestPractices/components/Section3.vue'
  import Section4 from '@/modules/BestPractices/components/Section4.vue'
  export default {
    components:{
      Section1,Section2,Section3,Section4
    },
    setup() {
        const {Search,Suggestions,questions,answwer,SearchSuggestions,visible} =useBestPractices()
        const title=ref('')
        const arrAnswers=ref([])
        
        SearchSuggestions()
      
        function querySearch(title,cb)
        {
            var s=title
           console.log("El valor de s "+s)
            Suggestions(s)
            console.log(questions.value)
           
            cb(questions.value)
           
          /*  console.log(questions.value.length)
            arrAnswers.value = questions.value.filter(element => element.title.includes(s));
            console.log(arrAnswers.value.length)*/
           // cb(arrAnswers.value)             
        }
        
        function handleSelect()
        {
          Search(title.value)
        }
        
        return {
          Search,Suggestions,querySearch,handleSelect,title,answwer,questions,SearchSuggestions,arrAnswers,visible
  
           }
  },
  }
  </script>
  
  <style scoped>
  
  select { 
    box-shadow: none;
    flex: 1;  padding: 0 1.2em;  color: rgb(14, 13, 13);
    background-color:#fff;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    height: 50px;
   -webkit-box-sizing: border-box;
    box-sizing: border-box;  
    }
    
  select::-ms-expand { display: none;}
  
  .combobox {
    position: relative;
    display: flex;  width: 20em;  height: 3em;
    border-radius: .25em;  overflow: hidden;
    color:rgb(14, 13, 13);
  }
    
  .combobox::after {
    content: '\25BC';  
    position: absolute;
    top: 0;  
    right: 0;  
    padding: 1em;
    background-color: #b567c5;
    transition: .25s all ease;
    pointer-events: none;
  }
    
  .combobox:hover::after {
    color: #f39c12;
  }
  
  /* Create the bordera and the surrounding */
  div ul {
    height: 45px;
    padding: 0 10px;
    text-align: left;
    border: 1px solid #33383b;
    background: #fafafa;
    border-radius: 3px;
  }
  
  div ul li {
    display: block;
    position: relative;
  }
  
  div ul li:first-child:before {
    position: absolute;
    content: " Menu ";
    position: relative;
    font-size: 1.6rem;
    font-size: 16px;
    font-weight: 600;
  }
  div ul li:first-child:after {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-size: 1.2rem;
    content: "\f0d7";
    color: #2267b9;
    padding-right: 10px;
  }
  
  /* Hide the li elements */
  div p {
    display: none;
    font-size: 1.5rem;
    font-size: 15px;
    text-decoration: none;
  
    color: #4a5564;
  }
  
  #category-btn {
    display: none;
  }
  
  .category-input {
    display: none;
  }
  
  div.div-is-visible ul {
    height: initial;
    background: #f1f1f1;
  }
  
  div.div-is-visible ul li:first-child:after {
    content: "\f00d";
  }
  div.div-is-visible ul li p {
    display: block;
    border-bottom: 2px solid #f1f1f1;
  }
  div.div-is-visible ul li p:hover {
    border-bottom: 2px solid #4a5564;
  }
  div.div-is-visible ul li:last-child {
    margin-bottom: 10px;
  }
  
  /* Make button visible again when div-is-visible class is toggled */
  div.div-is-visible #category-btn {
    display: block;
  }
  
  
  </style>