<template>
          <footer class="first-footer" >
    
            <div class="second-footer" style="background-color:#0476e0;">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-3 col-md-12 col-sm-12" style="padding-top:50px;"> 
                            <img src="@/assets/images/setescatalent.svg" class="img-responsive" style="width:45%" height="100%"/>
                          </div>
                        <div class="col-lg-3 col-md-12 col-sm-12" style="padding-top:50px;">                            
                            <p style="color:white;font-size:14px;">setesca@setesca.com +34 937 635 602</p>
                            <p style="color:white;font-size:14px;">C / Balmes 200 5º 4º 08006 Barcelona </p>                        
                        </div>                       
                        <div class="col-lg-3 col-md-12" style="padding-top:50px;">
                           <a href="https://www.linkedin.com/company/setesca/" target="_blank"> <img src="@/assets/icons/Linkedin32.png" class="img-responsive" /></a>
                           <a href="https://www.youtube.com/watch?v=mB6mp9xUUcg" target="_blank"> <img src="@/assets/icons/Youtube32.png" class="img-responsive" style="margin-left:25px;"/></a>
                           <a href="https://www.facebook.com/setesca" target="_blank"> <img src="@/assets/icons/facebook32.png" class="img-responsive"  style="margin-left:25px;"/></a>
                           <a href="https://twitter.com/setesca?lang=en" target="_blank"> <img src="@/assets/icons/twitter32.png" class="img-responsive" style="margin-left:25px;"/></a>
                        </div>
                        <div class="col-lg-3 col-md-12" style="padding-top:50px;">                            
                            <span style="color:white;font-size:14px;"> Barelona - Madrid - London - Mexico - Quito </span>
                        </div>
 
                        <br/>                      
                    </div>              
                </div>
            </div>
        </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>