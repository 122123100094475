import { createApp } from 'vue'
import jQuery from 'jquery'
global.$ = jQuery
import App from './App.vue'
import router from './router'
import store from './store'
import * as mdb from  'mdb-ui-kit'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import PrimeVue from 'primevue/config';
import  Dropdown  from 'primevue/dropdown'
import 'primevue/resources/primevue.min.css'
import "@/assets/hireyou/css/all.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// BOOTSTRAP CSS -->
import "@/assets/boostrap/css/bootstrap.css"
import "@/assets/hireyou/css/bootstrap.css"

//import "bootstrap/dist/css/bootstrap.css"

// OWL-CAROUSEL CSS -->
import "@/assets/hireyou/css/owl.carousel.min.css"
// SEARCH CSS -->
import "@/assets/hireyou/css/search.css"
// ANIMATE CSS -->
import "@/assets/hireyou/css/animate.css"

// SLICK CSS -->
import "@/assets/hireyou/css/slick.css"
// MMENU CSS -->
import "@/assets/hireyou/libs/mmenu/dist/mmenu.css"
// MBURGER CSS -->
import "@/assets/hireyou/libs/mburger/dist/mburger.css"
// STYLES CSS -->
import "@/assets/hireyou/css/styles.css"
import 'primeicons/primeicons.css';

const app =createApp(App);
app.use(store)
app.use(router)
app.use(jQuery)
app.use(PrimeVue)
app.use(ElementPlus)
app.component('Dropdown',Dropdown)
app.mount('#app')

import "@/assets/hireyou/js/jquery-3.3.1.min.js"
import "bootstrap/dist/js/bootstrap.min.js"



import "@/assets/hireyou/js/popper.min.js"

import "@/assets/hireyou/js/bootstrap.min.js"
import "@/assets/hireyou/libs/hc-sticky/dist/hc-sticky.js"
import "@/assets/hireyou/js/css_browser_selector.js"

