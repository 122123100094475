  <template>
            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container-fluid"><img src="@/assets/images/logo-Setesca-Talent.webp" class="img-fluid"/> 
                 <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">                         
                            <li class="nav-link"> <a class="nav-link" href="#">Home</a> </li>
                            <li class="nav-link"> <a class="nav-link" href="#">Busco Talento</a> </li>
                            <li class="nav-link"> <a class="nav-link" @click="goToJobForMe">Is it Job 4 Me</a> </li>
                            <li class="nav-link"> <a class="nav-link" @click="goToContact">Contactanos</a> </li>                            
                        </ul>
                        <div>  
                             
                            <el-button type="primary" plain  style="margin-bottom:5px;margin-right:5px;" @click="goToRegister">Registrarse</el-button>
                            <el-button type="primary" plain style="margin-bottom:5px;margin-right:5px;" @click="goToLogin">Entrar</el-button>
                             
                        </div>
                    </div>
                </div>
            </nav>
            <br/>
</template>

<script>

import { useRouter } from 'vue-router'

export default {
  setup() {
          
      const router = useRouter()
      const goToRegister= () =>{router.push({name: 'register'})}
      const goToLogin= () =>{router.push({name: 'authentication'})}
      const goToContact= () =>{router.push({name: 'contact'})}
      const goToJobForMe=()=>{router.push({name:'BestPractices'})}
     
    return {
        goToRegister,goToLogin,goToContact,goToJobForMe
    }
},
}
</script>

<style scoped>
div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #fff;
	color:#F07F29;
	border-color: #F07F29;
}

</style>