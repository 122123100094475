export const listOffers = ( state ) => {
    return state.offers?.InfojobsOffers[0]|| ''

}

export const setescaListOffers = ( state ) => {
    return state.offers?.SetescaOffers[0]|| ''    
}

export const getOffer = ( state ) => {
    return state.offerId || 0    
}

export const getSearch=(state)=>{
    return state.search
}