import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/job-list',
    name: 'jobList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobList" */ '@/modules/Offers/pages/ListsJobs.vue')
  },
  {
    path: '/contactanos',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobList" */ '@/modules/general/pages/Contact.vue')
  },
  {
    path: '/authentication',
    name: 'authentication',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '@/modules/security/pages/Login.vue')
  },
  {
    path: '/verification',
    name: 'verification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '@/modules/security/pages/Verification.vue')
  },  
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Register" */ '@/modules/security/pages/Register.vue')
  },
  {
    path: '/details-jobs',
    name: 'detailsJobs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Register" */ '@/modules/Offers/pages/DetailsJobs.vue')
  },
  {
    path: '/candidate',
    name: 'candidate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Register" */ '@/modules/candidates/components/Candidate.vue')
  }  ,
  {
    path: '/details-offer',
    name: 'detailsOffer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Register" */ '@/modules/Offers/pages/DetailsJobs.vue')
  },
  {
    path: '/myoffers',
    name: 'myoffers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '@/modules/candidates/components/MyOffers.vue')
  },    
  {
    path: '/myCurriculumVitae',
    name: 'mycv',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '@/modules/candidates/components/MyCurriculumVitae.vue')
  },  
  {
    path: '/bestpractices',
    name: 'BestPractices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '@/modules/BestPractices/pages/BestPractices.vue')
  },  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
